"use client";
import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";

export const Footer = ({ data }) => {
  const {
    description,
    address,
    phone,
    email,
    facebook,
    instagram,
    linkedin,
    youtube,
    whatsapp,
    google_map
  } = data || {};

  const t = useTranslations();
  const navigation = {
    main: [
      { name: t("home"), href: "/", hasHero: true },
      { name: t("about"), href: "/about" },
      { name: t("createWebsite"), href: "/website" },
      { name: t("module"), href: "/module" },
    ],
    social: [
      {
        name: "Facebook",
        href: facebook,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Instagram",
        href: instagram,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 22 22" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "LinkedIn",
        href: linkedin,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M24.7154 14.9622V23.4762H19.6042V15.5324C19.6042 13.537 18.8647 12.1753 17.0151 12.1753C15.6027 12.1753 14.7623 13.093 14.3924 13.981C14.2575 14.2984 14.2227 14.7403 14.2227 15.1842V23.4762H9.10975C9.10975 23.4762 9.1785 10.0222 9.10975 8.62842H14.2218V10.7329C14.2115 10.7487 14.1982 10.7657 14.1883 10.7811H14.2218V10.7329C14.9012 9.72258 16.1141 8.27948 18.8291 8.27948C22.1937 8.27948 24.7154 10.4018 24.7154 14.9622ZM3.76184 1.47095C2.01271 1.47095 0.868958 2.5788 0.868958 4.03602C0.868958 5.46129 1.98005 6.60276 3.69438 6.60276H3.72832C5.51141 6.60276 6.61993 5.46129 6.61993 4.03602C6.58641 2.5788 5.51141 1.47095 3.76184 1.47095ZM1.17273 23.4762H6.2835V8.62842H1.17273V23.4762Z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "YouTube",
        href: youtube,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Whatsapp",
        href: whatsapp,
        icon: (props) => (
          <svg xmlns="http://www.w3.org/2000/svg" {...props} xmlnsXlink="http://www.w3.org/1999/xlink" fill="currentColor" height="800px" width="800px" version="1.1" id="Layer_1" viewBox="0 0 308 308" xmlSpace="preserve" style={{ fill: "#A271F2" }}>
            <g id="XMLID_468_">
              <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156   c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687   c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887   c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153   c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348   c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802   c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922   c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0   c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458   C233.168,179.508,230.845,178.393,227.904,176.981z" />
              <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716   c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396   c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z    M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188   l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677   c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867   C276.546,215.678,222.799,268.994,156.734,268.994z" />
            </g>
          </svg>
        ),
      },
    ],
    contact: [
      {
        desc: address,
        href: google_map,
        icon: (props) => (
          <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C15.87 2 19 5.13 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9C5 5.13 8.13 2 12 2ZM7 9C7 11.85 9.92 16.21 12 18.88C14.12 16.19 17 11.88 17 9C17 6.24 14.76 4 12 4C9.24 4 7 6.24 7 9ZM12 11.5C10.6193 11.5 9.5 10.3807 9.5 9C9.5 7.61929 10.6193 6.5 12 6.5C13.3807 6.5 14.5 7.61929 14.5 9C14.5 10.3807 13.3807 11.5 12 11.5Z"
            />
          </svg>
        ),
      },
      {
        desc: email,
        href: `mailto:${email}`,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        desc: phone,
        href: `tel:${phone?.replace(/[^\d]/g, '')}`,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3Z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  };

  return (
    <footer className="bg-mainBgPurple text-mainLightPurpleText w-full mx-auto border-t-[1px] border-slate-200 border-solid">
      <div className="px-4 flex-col gap-[30px] lg:flex-row mx-auto max-w-[1296px] flex lmd:items-start justify-between lg:gap-[61px] gap-y-20 lg:py-20 md:py-10 py-6 p-2">

          <div className="flex flex-col lg:w-1/2">
            <div className="pt-[72px] md:pt-0 flex flex-col gap-8 md:items-start">
              <Link href="/" className=" cursor-pointer">
                <span className="sr-only">{data?.title}</span>
                <Image
                  className="h-12 w-auto"
                  width={200}
                  height={40}
                  src={data?.logo}
                  alt="logo"
                />
              </Link>
              <p className="text-base md:text-sm text-mainLightPurpleText leading-6 max-w-[456px] md:max-h-[130px] mb-10">
                {description}
              </p>
              <div>
              <p className="font-bold mb-4">
                {t("followUs")}
              </p>
              <div className="flex space-x-4 justify-start items-center">
                {navigation.social.map((item) => (
                item.href && <a
                  target="_blank"
                  key={item.name}
                  href={item.href}
                  className="text-mainLightPurple hover:text-mainLightPurpleHover active:text-mainLightPurpleActive flex justify-center items-center"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="lg:h-10 h-8 lg:w-10 w-8" aria-hidden="true" />
                </a>
              ))}
              </div>
            </div>
            </div>
          </div>

          <div className="flex gap-16 flex-col md:flex-row lg:w-1/2">
            <div>
              <h3 className="mb-0 md:mb-8 text-mainDarkPurple font-bold lg:text-2xl text-xl uppercase">
                {t("contactUs")}
              </h3>
              <ul role="list" className="mt-10 space-y-2">
                {navigation.contact.map((item) => (
                  item.desc && <li key={item.desc}>
                    <a
                      target="_blank"
                      href={item.href}
                      className="text-lg  leading-6 font-medium text-mainLightPurpleText hover:text-mainLightPurpleHover active:text-mainLightPurpleActive flex items-center gap-2 max-w-[320px] "
                    >
                      <item.icon className="!h-6 !w-6 flex-shrink-0 text-mainLightPurple" aria-hidden="true" />
                      <span className="text-lg leading-6 font-medium text-mainLightPurpleText hover:text-mainLightPurpleTextHover">
                        {item.desc}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div >
              <h3 className="mb-0 md:mb-8 text-mainDarkPurple font-bold lg:text-2xl text-xl uppercase">
                {t("siteMap")}
              </h3>
              <ul role="list" className="mt-10 space-y-2">
                {navigation.main.map((item) => (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      className="text-lg leading-6 font-medium text-mainLightPurpleText hover:text-mainLightPurpleTextHover"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>
        
        <div className="flex justify-between border-t text-mainLightPurpleText border-slate-200 pt-4">
          <div className="flex-col mb-8 md:mb-0 lmd:flex-row-reverse w-full max-w-[1296px] flex justify-between lmd:items-center items-start mx-auto px-4">
            <div className="py-4 flex-col md:flex-row gap-2 text-[16px] flex md:text-base lmd:items-center items-start justify-between md:gap-[16px] ">
              <div className="flex gap-[8px] sm:justify-center items-center text-mainLightPurpleText">
                <Image src={"/icons/phone-call.svg"} alt="phone" width={16} height={16} />
                <p className="text-[16px] whitespace-nowrap">
                  {phone}
                </p>
              </div>
              <span className="hidden md:block min-h-[24px] w-[1px] bg-slate-200"></span>
              <div className="flex gap-[8px] sm:justify-center items-center">
                <Image  src={"/icons/email.svg"} alt="email" width={16}  height={16} />
                <p>{email}</p>
              </div>
            </div>
            <div className="border-t-[1px] border-slate-200 pt-3 mx-auto md:mx-0 md:border-0 md:p-0 text-base w-full text-left text-mainLightPurpleText flex leading-5 lg:h-[10px] ">
              &copy; Copyright 2004-{new Date().getFullYear()}{"  "}
              <Link href={`https://${data?.name}/`}>
                {data?.title}. {t("reserve")}
              </Link>
            </div>
          </div>
        </div>
    </footer>
  );
};
