"use client";
import { memo, useState } from "react";

import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useTranslations } from "next-intl";

import { setAuthToken } from "@/store";
import { Dialog } from "@headlessui/react";
import Select, { components } from "react-select"
import { useDispatch, useSelector } from "react-redux";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { formattedSiteUrl, getFromLocale, removeFromLocale } from "@/utils";

export const BtnGroup = memo(function BtnGroup({ locale, referer, data, default_currency, currency: curr }) {
  const router = useRouter()
  const t = useTranslations();
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.auth);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: t("home"), href: "/", hasHero: true },
    { name: t("about"), href: "/about" },
    { name: t("createWebsite"), href: "/website" },
    { name: t("sections"), href: "/module" },
    { name: t("blog"), href: "/blog" },
  ];

  const handleLogout = () => {
    removeFromLocale();
    dispatch(setAuthToken(null));
  };

  const options = [
    {
      value: "az",
      label: "AZ",
      image: "/static/az-flag.svg",
    },

    {
      value: "en",
      label: "EN",
      image: "/static/en-flag.svg",
    },
  ];
  const optionsCurrency = [
    { value: "usd", label: "$" },
    { value: "azn", label: "₼" },
  ];

  const defaultCurrencyValue = optionsCurrency.find((item) => item?.value == curr);
  const [selectedCurrency, setSelectedCurrency] = useState({
    ...defaultCurrencyValue
  });


  const customStylesCurrency = {
    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "white",
      padding: "0px",
      margin: 0,
      border: "none",
      cursor: "pointer",
      boxShadow: "none",
      fontSize: "16px",
      outline: "none",
      "&:hover": {
        border: "none",
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
      fontSize: "22px",
    }),

    singleValue: (defaultStyles) => ({
      ...defaultStyles, color: "black", fontSize: "22px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
      backgroundColor: state.isSelected ? "#f1e8ff" : "white",
      border: "none",
      paddingLeft: "17px",
      fontSize: "16px",
      "lineHeight": "1rem",
      cursor: "pointer",
      "&:active": {
        backgroundColor: "#f1e8ff"
      }
    }),
  };

  const customStyles = {
    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "white",
      control: (defaultStyles) => ({
        ...defaultStyles,
        backgroundColor: "white",
        padding: "0px",
        margin: 0,
        border: "none",
        cursor: "pointer",
        boxShadow: "none",
        fontSize: "16px",
        outline: "none",
        "&:hover": {
          border: "none",
        }
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: "0",
        fontSize: "22px",
        fontWeight: "bold"
      }),

      singleValue: (defaultStyles) => ({
        ...defaultStyles, color: "black",
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "black" : "black",
        backgroundColor: state.isSelected ? "#f1e8ff" : "white",
        border: "none",
        paddingLeft: "17px",
        fontSize: "16px",
        "lineHeight": "1rem",
        cursor: "pointer",
        "&:active": {
          backgroundColor: "#f1e8ff"
        }
      }),
      padding: "0px",
      margin: 0,
      border: "none",
      cursor: "pointer",
      fontWeight: "bold",
      boxShadow: "none",
      maxWidth: "64px",
      outline: "none",
      "&:hover": {
        border: "none",
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 0",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "black", fontWeight: "bold" }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#f1e8ff" : "white",
      justifyContent: "center",
      border: "none",
      cursor: "pointer",
      "&:active": {
        backgroundColor: "#f1e8ff"
      }
    }),
  };

  const CustomOption = ({ innerProps, label, isFocused, isSelected, data }) => (
    <div
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: "17px",
        paddingBlock: "8px",
        // paddingInline: "8px",
        cursor: "pointer",
        maxWidth: "64px",
        borderRadius: "5%",
        transition: "background-color 0.3s ease", // Transition effekti əlavə edirik
        backgroundColor: isFocused || isSelected ? "#f0f0f0" : "transparent", // Hover və seçilmişdə yeni background
      }}
    >
      <Image
        src={data?.image}
        className="w-6 h-4 object-cover"
        width={25}
        height={16}
        alt={label}
        style={customStyles.optionImage}
      />
      {/* <span style={{ marginLeft: "12px", color: "#94A3B8" }}>{label}</span> */}
    </div>
  );

  const CustomControl = ({ children, ...props }) => {
    const selectedOption = props.getValue()[0];

    return (
      <components.Control {...props}>
        {selectedOption && selectedOption?.image && (
          <Image
            width={25}
            height={16}
            src={selectedOption.image}
            alt={selectedOption.label}
            style={customStyles.controlImage}
            className="w-6 h-4 object-cover"
          />
        )}
        {children}
      </components.Control>
    );
  };

  const customComponents = {
    Control: CustomControl,
    IndicatorSeparator: () => null,
  };
  const defaultValue = locale == "en" ? options[1] : options[0];
  const [language, setLanguage] = useState({
    ...defaultValue,
  });

  const changeLanguage = (selectedOption) => {
    const selectedLang = options.find(
      (item) => item.value == selectedOption.value
    );
    setLanguage(selectedLang);
    document.cookie = `lang=${selectedOption.value}; path=/; max-age=${30 * 24 * 60 * 60
      }`;
    window.location.reload();

  };
  const changeCurrency = (selectedOption) => {
    const selectedCurr = optionsCurrency.find(
      (item) => item.value == selectedOption.value
    );
    setSelectedCurrency(selectedCurr);
    document.cookie = `currency=${selectedOption.value}; path=/; max-age=${30 * 24 * 60 * 60
      }`;
    router.refresh()
  };
  return (
    <>
      <nav
        className={`mx-auto flex max-w-[1920px] items-center justify-between p-6 gap-x-2 font-manrope lg:px-8`}
        aria-label="Global"
      >
        <div className="flex">
          <Link href="/" className="-m-1.5 p-1.5 cursor-pointer">
            <span className="sr-only">Site.Az</span>
            <Image
              className="h-6 w-auto"
              width={160}
              height={32}
              src={data?.logo}
              alt="logo"
            />
          </Link>
        </div>
        <div className="hidden lg2:flex lg:gap-x-4 xl:gap-x-15 nav">
          {navigation.map((item) => (
            <Link
              key={item.name}
              href={item.href}
              className="text-base font-bold leading-6 text-gray-900 hover:text-mainLightPurple cursor-pointer whitespace-nowrap"
            >
              {item.name}
            </Link>
          ))}
        </div>

        <div className="flex items-center justify-end gap-x-6">
          {authToken ? (
            <div className="flex items-center gap-x-4">
              {/* Currency Select Desktop  */}
              <Select
              id="SelectCurrency"
                value={selectedCurrency}
                options={optionsCurrency}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={changeCurrency}
                styles={customStylesCurrency}
                isSearchable={false}
                defaultValue={defaultCurrencyValue}
                instanceId={"SelectCurrency"}
                className="hidden lg:block font-bold"
              />

              <Select
              id="SelectLanguage"
                isSearchable={false}
                onChange={changeLanguage}
                value={language}
                options={options}
                components={{ Option: CustomOption, ...customComponents }}
                styles={customStyles}
                defaultValue={defaultValue}
                className="hidden lg:block"
              />
              <Link
                target="_blank"
                href={`${formattedSiteUrl(referer)
                  }/dashboard?access_token=${getFromLocale()}&lang=${locale}`}
                className="  hidden lg:block rounded-md bg-purpleGradient  px-5 lg:leading-6 text-base font-semibold text-white shadow-sm pt-[.56rem] pb-[.56rem]"
              >
                {t("dashboard")}
              </Link>
              <button
                onClick={() => handleLogout()}
                className="hidden lg:block lg:text-sm font-manrope text-sm font-bold lg:leading-6 px-5 pt-[.56rem] pb-[.56rem] text-[#A271F2] border border-[#A271F2] rounded-[8px]"
              >
                {t("sign-out")}
              </button>
            </div>
          ) : (
            <div className="flex items-center gap-x-4">
              {/* Currency Select Desktop  */}
              <Select
              id="SelectCurrency2"
                value={selectedCurrency}
                options={optionsCurrency}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={changeCurrency}
                styles={customStylesCurrency}
                isSearchable={false}
                defaultValue={defaultCurrencyValue}
                instanceId={"SelectCurrency"}
                className={`hidden lg:block font-bold`}
              />

              <Select
              id="selectLanguage2"
                isSearchable={false}
                onChange={changeLanguage}
                options={options}
                value={language}
                components={{ Option: CustomOption, ...customComponents }}
                styles={customStyles}
                defaultValue={defaultValue}
                className="hidden lg:block"
              />
              <Link
                href="/sign-up"
                className=" hidden whitespace-nowrap lg:block rounded-md bg-purpleGradient px-6 lg:leading-6 text-base font-bold text-white shadow-sm pt-[.56rem] pb-[.56rem]"
              >
                {t("sign-up")}
              </Link>
              <Link
                href="/sign-in"
                className="pt-[.56rem] pb-[.56rem] hidden lg:block whitespace-nowrap text-base min-w-[120px] bg-white btnLspace text-[#A271F2] border border-[#A271F2] font-bold text-center rounded-[8px] font-manrope opacity-100"
              >
                {t("sign-in")}
              </Link>
            </div>
          )}
        </div>
        <div className="flex lg:hidden gap-x-2">
          {/* Currency Select Tablet  */}
          <Select
          id="selectCurrency3"
            value={selectedCurrency}
            options={optionsCurrency}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={changeCurrency}
            styles={customStylesCurrency}
            isSearchable={false}
            defaultValue={defaultCurrencyValue}
            instanceId={"SelectCurrency"}
            className="xmd:block hidden font-bold"
          />
          {/* Lang Select Tablet  */}
          <Select
          id="selectLanguage3"
            isSearchable={false}
            onChange={changeLanguage}
            options={options}
            value={language}
            components={{ Option: CustomOption, ...customComponents }}
            styles={customStyles}
            defaultValue={defaultValue}
            className="xmd:block hidden"
          />

          <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5" onClick={() => setMobileMenuOpen(true)} >
            <span className="sr-only">{t("openMainMenu")}</span>
            <Bars3Icon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
      </nav>

      <Dialog
        as="div"
        className="lg:hidden z-99999"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-9999 bg-gray-700/90" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-99999 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between gap-x-6">
            <Link
              href="/"
              className="-m-1.5 p-1.5 cursor-pointer focus:outline-none"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Site.Az</span>
              <Image
                className="h-8 w-auto"
                width={120}
                height={40}
                src={data?.logo}
                alt="logo"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5  text-gray-700 hover:text-[#14AEED]"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6 " aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            {/* divide-y divide-gray-500/10 */}
            <div className="-my-6 ">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    href={item.href}
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-mainDarkPurple hover:text-mainLightPurpleHover font-bold hover:bg-gray-50 transition-all duration-200"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-2 flex items-start justify-start text-start gap-x-4">
                {/* Mobile Menu Currency & Language  */}
                {/* <div className="w-[120px]"> */}
                <Select
                id="selectCurrency4"
                  value={selectedCurrency}
                  options={optionsCurrency}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={changeCurrency}
                  styles={customStylesCurrency}
                  isSearchable={false}
                  defaultValue={defaultCurrencyValue}
                  instanceId={"SelectCurrency"}
                  className="block lg:hidden font-bold"
                />
                {/* </div>
                <div className="w-[120px]"> */}
                <Select
                id="selectLanguage4"
                  isSearchable={false}
                  onChange={changeLanguage}
                  options={options}
                  value={language}
                  components={{ Option: CustomOption, ...customComponents }}
                  styles={customStyles}
                  defaultValue={defaultValue}
                  className="block lg:hidden "
                />
                {/* </div> */}
              </div>
              <div className="py-6 w-full flex gap-4 items-start justify-start text-start">
                {authToken ? (
                  <Link
                    target="_blank"
                    href={`${formattedSiteUrl(referer)
                      }/dashboard?access_token=${getFromLocale()}&lang=${locale}`}
                    onClick={() => setMobileMenuOpen(false)}
                    className=" rounded-md bg-purpleGradient px-5 py-2 leading-6 text-sm font-semibold text-white shadow-sm pt-[.56rem] pb-[.56rem] "
                  >
                    {t("dashboard")}
                  </Link>
                ) : (
                  <>
                    <Link
                      href="/sign-up"
                      onClick={() => setMobileMenuOpen(false)}
                      className=" rounded-md bg-purpleGradient px-5 py-2 leading-6 text-sm font-semibold text-white shadow-sm pt-[.56rem] pb-[.56rem] "
                    >
                      {t("sign-up")}
                    </Link>
                  </>
                )}

                {authToken ? (
                  <button
                    onClick={() => {
                      handleLogout();
                      setMobileMenuOpen(false);
                    }}
                    className="font-manrope font-bold leading-6 px-5 text-sm pt-[.56rem] pb-[.56rem] text-mainLightPurple border border-mainLightPurple] rounded-[8px]"
                  >
                    {t("sign-out")}{" "}
                  </button>
                ) : (
                  <>
                    <Link
                      href="/sign-in"
                      onClick={() => setMobileMenuOpen(false)}
                      className="font-manrope font-bold leading-6 px-5 text-sm pt-[.56rem] pb-[.56rem] text-mainLightPurple border border-mainLightPurple rounded-[8px]"
                    >
                      {t("sign-in")}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
});
